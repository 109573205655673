.form-login {
    color: black;
    background-color: #fafafa;
    justify-content: center;
    align-items: center;
    display: block;
    padding: 30px 30px;
    border-radius: 6px;
  }

.form-inputs {
    display: block;
    direction: initial;
}  

.text-input {
    width: 100%;
}

.button-login {
    width: 100%;
}