/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Noto+Sans&display=swap'); */

body {
  margin: 0;
  font-family: "EMprint W05 Regular_web";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "EMprint W05 Regular_web";
  src: local("EMprint W05 Regular_web"),
    url("./fonts/EMprint\ W05\ Regular_web.ttf") format("truetype")
  ;
}