body, main {
  box-sizing: border-box;
  background-color: #FFFF; 
  font-size: 14px;
  min-height: 100vh;
  max-height: 100%;
}

#header-prod {
  padding: 0px;
  background: #094B9B;
  height: 65px;
  position: sticky;
  top: 0
}

#header-qas {
  padding: 0px;
  background: #d8051f;
  height: 65px;
  position: sticky;
  top: 0
}
/* 
nav {
  align-items: center;
  height: 60px;
} */

/* .sidenav {
  width: 220px;
  background: #094B9B;
  position: fixed;
  height: 100%;
  padding: 0 30px;
  left: -100%;
  transition: all 0.3s;
  color: white
}

.sidenav.activate {
  left: 0;
  height: 100%;
  z-index: 300;
  overflow-y: auto;
} */



.logo {
  width: 180px;
  padding: 5px 10px;
  align-self: center;
}

/* header svg {
  padding: 10px;
  font-size: 20px;
} */

.login {
  background-color: #42489b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}


/* .main {
  font-size: 14px;
} */

.breadcrumb {
  margin-left: 15px;
  text-align: center;
}